<template>
    <div class="app" id="app">
        <Layout>
            <div class="main">
                <div class="swiper-index">
                    <!-- Additional required wrapper -->
                    <div class="swiper-wrapper">
                        <!-- Slides -->
                        <div class="swiper-slide" v-for="(item, index) in bannerList" :key="'banner' + index">
                            <img :src="item.img" alt="开放能力" class="slide-bg" />
                            <div  @click="toLinkByLogin('/changeinfo.html')" v-if="index ===1" class="btn">{{$t('btnJoin')}}</div>
                        </div>
                    </div>
                    <div class="swiper-pagination-index"></div>
                </div>
                <Panel :params="{title: opennessData.title, subTitle: opennessData.subTitle}">
                    <template>
                        <div class="open-select">
                            <div class="select-line" :class="'active' + openSelectIndex"></div>
                            <div
                                class="select-item"
                                v-for="(item, index) in opennessData.data"
                                :key="'open-item' + index"
                                :class="openSelectIndex === index ? 'active' : ''"
                                @mouseenter="changeOpenSelect(index)"
                            >
                                <i class="icon iconfont" v-html="item.icon"></i>
                                <p class="select-text">{{ item.title }}</p>
                            </div>
                        </div>
                        <div class="open-content">
                            <div
                                class="open-content-item"
                                v-for="(item, index) in opennessData.data"
                                :key="'open-content' + index"
                                :class="{active: openSelectIndex === index, action: openSelectContentIndex === index}"
                            >
                                <h3>{{ item.desc }}</h3>
                                <div class="item-box">
                                    <div
                                        class="item-box-child"
                                        v-for="(clist, cIndex) in item.list"
                                        :key="'open-box' + cIndex"
                                    >
                                        <div
                                            class="child-content"
                                            :class="item.className || ''"
                                            v-for="(cItem, cLIndex) in clist"
                                            :key="'open-box-child' + cLIndex"
                                        >
                                            <i class="icon iconfont">&#xe72b;</i>
                                            <span>{{ cItem }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-desc">{{ opennessData.more }}</div>
                            </div>
                        </div>
                    </template>
                </Panel>
                <Panel :params="{title: scenarios.title, subTitle: scenarios.subTitle}">
                    <template>
                        <div class="scene-box">
                            <div class="scene-item" v-for="(item, index) in scenarios.data" :key="'scene' + index">
                                <div class="item-title">
                                    <i class="icon iconfont" :class="item.iconClass"></i>
                                    <h3>{{ item.title }}</h3>
                                </div>
                                <div class="item-text">
                                    {{ item.text }}
                                </div>
                            </div>
                        </div>
                    </template>
                </Panel>

                <Panel :params="{title: tccantage.title, subTitle: tccantage.subTitle, bgType: 1}">
                    <template>
                        <div class="tcc-box">
                            <div class="tcc-item" v-for="(item, index) in tccantage.data" :key="'tcc' + index">
                                <i class="icon iconfont" v-html="item.icon"></i>
                                <h3>{{ item.title }}</h3>
                                <p>{{ item.text }}</p>
                            </div>
                        </div>
                    </template>
                </Panel>

                <Panel :params="{title: process.title, subTitle: process.subTitle}">
                    <template>
                        <div class="pro-box">
                            <div class="pro-item" v-for="(item, index) in process.data" :key="'pro' + index">
                                <i class="icon iconfont" v-html="item.icon"></i>
                                <p>{{ item.text }}</p>
                                <div class="text-bg">{{ index + 1 }}</div>
                            </div>
                        </div>
                        <div class="pro-btnbox">
                            <div @click="toLinkByLogin('/changeinfo.html')" class="btn">{{ $t('btnJoin') }}</div>
                            <div v-if="$t('btnSDK') !== 'hide'" class="btn">{{ $t('btnSDK') }}</div>
                        </div>
                    </template>
                </Panel>
                <Panel :params="{title: partners.title, subTitle: partners.subTitle}">
                    <template>
                        <div class="par-box">
                            <div class="par-item" v-for="(item, index) in partners.data" :key="'par' + index">
                                <img :src="item.url" alt="合作伙伴" />
                            </div>
                        </div>
                    </template>
                </Panel>

                <div class="join-box" :class="this.$i18n.locale">
                    <p>{{$t('joinText')}}</p>
                    <div class="btn" @click="toLinkByLogin('/changeinfo.html')">{{$t('btnJoin')}}</div>
                </div>
            </div>
        </Layout>
    </div>
</template>
<script>
import Swiper from 'swiper/dist/js/swiper';
import 'swiper/dist/css/swiper.css';
import Layout from '@/components/layout/layout';
import Panel from '@/components/panel';
import {initContentData} from '@/model/pages/home';
import {getT} from '@/model/i18n/apiMessage';
import {getCookieValueByName} from '@/utils/tool';

export default {
    data() {
        return {
            renderStatus: 1,

            // 轮播
            bannerList: [],

            // 内容
            opennessData: {},
            openSelectIndex: 0,
            openSelectContentIndex: 0,

            scenarios: {},

            tccantage: {},

            process: {},

            partners: {},
        };
    },
    components: {
        Layout,
        Panel,
    },

    mounted() {
        console.log('render contextapp');

        this.$nextTick(() => {
            new Swiper('.swiper-index', {
                speed: 1000,
                loop: true,
                autoplay: {
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination-index',
                    // type: 'custom',
                    bulletClass: 'pagination-tab',
                    bulletActiveClass: 'pagination-tab-active',
                    clickable: true,
                },
            });

            // console.log(swiper);
        });
    },

    methods: {
        updateI18n() {
            // 语言切换钩子
            this.updateBannerList();

            initContentData(this);
        },
        updateBannerList() {
            let lang = 'en';
            if (this.$i18n.locale !== 'en') {
                lang = 'cn';
            }
            this.bannerList = [
                {
                    img: `/images/index/${lang}/banner1.jpg`,
                },
                {
                    img: `/images/index/${lang}/banner2.jpg`,
                },
                {
                    img: `/images/index/${lang}/banner3.jpg`,
                },
                {
                    img: `/images/index/${lang}/banner4.jpg`,
                },
            ];
        },

        toLinkByLogin(link) {
            if (getCookieValueByName('userId')) {
                return location.href = link;
            }
            this.$myConfirm(getT('unLogin'));
        },

        changeOpenSelect(index) {
            this.openSelectIndex = index;
            setTimeout(() => {
                this.openSelectContentIndex = index;
            }, 50);
        },

    },
};
</script>
<style lang="less">

.main {
    font-family: PingFang SC, Helvetica Neue, Helvetica, Tahoma, Arial, Microsoft YaHei, Roboto;
    background: #222;

    .swiper-index {
        position: relative;
        min-width: 1400px;
        width: 100%;
        height: 500px;
        margin: 0 auto;
        overflow: hidden;

        .swiper-slide {
            position: relative;
            overflow: hidden;
        }

        .slide-bg {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            margin: 0 auto;
            height: 100%;
        }

        .swiper-pagination-index {
            position: absolute;
            display: flex;
            justify-content: center;
            width: 100%;
            bottom: 30px;
            z-index: 9;
        }

        .pagination-tab {
            display: block;
            width: 35px;
            height: 6px;
            background: #fff;
            opacity: 0.4;
            cursor: pointer;
            border-radius: 2px;

            & + .pagination-tab {
                margin-left: 6px;
            }

            &.pagination-tab-active {
                opacity: 1;
            }
        }
    }

    .open-select {
        position: relative;
        margin: 56px auto 0;
        width: 1220px;
        height: 110px;
        display: flex;
        justify-content: space-between;

        .select-line {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100px;
            z-index: 9999;
            height: 5px;
            background: linear-gradient(90deg, #ff7107, #fbc44f);
            transition: transform 0.5s;

            &.active0 {
                transform: translateX(0);
            }
            &.active1 {
                transform: translateX(180px + 100px);
            }
            &.active2 {
                transform: translateX(180px * 2 + 100px * 2);
            }
            &.active3 {
                transform: translateX(180px * 3 + 100px * 3);
            }
            &.active4 {
                transform: translateX(180px * 4 + 100px * 4);
            }

            &::after {
                position: relative;
                content: '';
                width: 0;
                height: 0;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-top: 7px solid #fb9539;
                left: 50%;
                top: 12px;
                margin-left: -7px;
            }
        }

        .select-item {
            width: 100px;
            color: #5e5e5e;
            font-size: 14px;
            cursor: default;
            text-align: center;

            &:hover,
            &.active {
                color: #fe6f3d;
            }

            .iconfont {
                font-size: 40px;
            }

            .select-text {
                margin-top: 10px;
            }
        }
    }

    .open-content {
        position: relative;
        background: #35373f;
        color: #fff;
        height: 420px;

        .open-content-item {
            position: relative;
            display: none;
            width: 1220px;
            height: 100%;
            margin: 0 auto;
            padding-top: 83px;

            background-position: 745px 15px;
            background-repeat: no-repeat;

            &:nth-of-type(1) {
                background-image: url(assets/images/open-01.png);
            }
            &:nth-of-type(2) {
                background-image: url(assets/images/open-02.png);
            }
            &:nth-of-type(3) {
                background-image: url(assets/images/open-03.png);
            }
            &:nth-of-type(4) {
                background-image: url(assets/images/open-04.png);
            }
            &:nth-of-type(5) {
                background-image: url(assets/images/open-05.png);
            }

            &.active {
                display: block;
                opacity: 0;
                transform: translateY(60px);
            }
            &.action {
                transition: opacity 0.7s, transform 0.7s;
                opacity: 1;
                transform: translateY(0);
            }

            h3 {
                font-size: 20px;
                margin-bottom: 30px;
            }
            .item-box {
                font-size: 13px;
                width: 100%;

                .iconfont {
                    color: #fe6f3d;
                    font-size: 16px;
                    margin-right: 10px;
                }

                .item-box-child {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    margin-bottom: 22px;

                    .child-content {
                        min-width: 153px;
                        margin-right: 20px;
                        white-space: nowrap;

                        &.w203 {
                            width: 203px;
                        }
                        &.w250 {
                            width: 250px;
                        }
                        &.w310 {
                            width: 310px;
                        }
                        &.w400 {
                            width: 400px;
                        }
                    }
                }
            }
            .item-desc {
                position: absolute;
                top: 340px;
                font-size: 12px;
            }
        }
    }

    .scene-box {
        width: 1000px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        justify-content: center;
        padding-bottom: 52px;

        .scene-item {
            width: 379px;
            height: 330px;
            margin-top: 40px;
            border: 1px solid #e4e4e4;

            &:nth-of-type(2n) {
                margin-left: 15px;
            }

            &:hover {
                border-color: #fe6f3d;

                .item-title {
                    height: 180px;
                }
            }

            &:nth-of-type(2) {
                .item-title {
                    background-image: url(assets/images/app-03.png);
                }
            }
            &:nth-of-type(3) {
                .item-title {
                    background-image: url(assets/images/app-04.png);
                }
            }
            &:nth-of-type(4) {
                .item-title {
                    background-image: url(assets/images/app-05.png);
                }
            }

            .item-title {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 200px;
                color: #fff;
                background: url(assets/images/app-02.png) no-repeat 0 0;
                background-size: 100% auto;
                align-items: center;
                justify-content: center;
                transition: height 0.5s;

                .iconfont {
                    font-size: 96px;
                    line-height: 96px;
                }
                h3 {
                    width: 100%;
                    text-align: center;
                    font-size: 18px;

                    margin-top: 10px;
                }
            }
            .item-text {
                font-family: 'PingFang SC', 'Helvetica Neue', Helvetica, Tahoma, Arial;
                font-size: 13px;
                color: #a1a1a1;
                line-height: 18px;
                padding: 25px 0 10px 10px;
            }
        }
    }

    .tcc-box {
        width: 1220px;
        display: flex;
        justify-content: space-between;
        margin: 75px auto 0;
        padding-bottom: 100px;

        .tcc-item {
            text-align: center;

            .iconfont {
                font-size: 80px;
                color: #ff7107;
                background-image: -webkit-gradient(linear, 0 0, 0 bottom, from(#ff7107), to(#fbc44f));
                background-clip: text;
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke: unset;
            }

            h3 {
                font-size: 14px;
                margin-top: 25px;
                color: #a1a1a1;
                font-weight: normal;
                line-height: 20px;
            }

            p {
                font-size: 18px;
                color: #5e5e5e;
                line-height: 25px;
                margin-top: 9px;
            }
        }
    }

    .pro-box {
        width: 1220px;
        margin: 75px auto 78px;
        display: flex;
        justify-content: space-between;

        .pro-item {
            padding-top: 26px;
            position: relative;
            width: 216px;
            height: 170px;
            border: 1px solid #e4e4e4;
            color: #a1a1a1;
            font-weight: 300;

            .iconfont {
                font-size: 60px;
                line-height: 60px;
                margin-left: 27px;
            }

            p {
                width: 144px;
                font-size: 13px;
                line-height: 18px;
                margin: 14px 0 0 20px;
            }
            .text-bg {
                position: absolute;
                right: -3px;
                bottom: -10px;
                font-size: 100px;
                line-height: 100px;
                height: 100px;
                color: #e4e4e4;
            }
        }
    }

    .pro-btnbox {
        display: flex;
        justify-content: center;
        padding-bottom: 110px;

        .btn {
            padding: 0 10px;
            min-width: 138px;
            height: 42px;
            text-align: center;
            line-height: 42px;
            font-size: 14px;
            border: 1px solid #fe6f3d;
            color: #fe6f3d;
            cursor: pointer;
            background: #fff;

            & + .btn {
                margin-left: 40px;
            }

            &:hover {
                color: #fff;
                background: #fe6f3d;
            }
        }
    }

    .par-box {
        margin: 80px auto 0;
        padding-bottom: 100px;
        width: 1255px;
        display: flex;
        flex-wrap: wrap;

        .par-item {
            margin: 10px 12.5px;
            width: 224px;
            height: 83px;
            border: 1px solid #ededed;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                display: block;
                width: 202px;
            }
        }

    }
    .join-box {
        width: 100%;
        min-width: 1400px;
        height: 220px;
        background: url(assets/images/bg-ban-bottom.jpg) no-repeat center center;
        background-size: auto 100%;
        color: #fff;
        padding-top: 50px;

        p {
            text-align: center;
            font-size: 24px;
            line-height: 33px;
            
        }
        
    }

    .join-box,
    .swiper-slide {
        .btn {
            width: 170px;
            display: block;
            height: 42px;
            line-height: 42px;
            color: #ffff;
            font-size: 16px;
            border: 1px solid #fff;
            margin: 40px auto 0;
            text-align: center;
            background: none;
            cursor: pointer;

            &:hover {
                background: #fff;
                color: #000;
            }
        }
    }

    .swiper-slide {
        position: relative;

        .btn {
            position: absolute;
            top: 66%;
            left: 13.2%;
        }

    }
}
</style>
