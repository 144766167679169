import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import {initAPMLabel} from './utils/config';
import {Key_i18n, Value_Default_i18n} from '@/model';
import {message} from '@/model/i18n/index';
import {initCommonToMessage} from '@/model/i18n/tool';
import pluginUpdateLocale from '@/utils/plugins/pluginUpdateLocale';
import pluginCommonUI from '@/utils/plugins/pluginCommonUI';
import './api';

window.Vue = Vue;

Vue.config.productionTip = false
Vue.use(VueI18n);
Vue.use(pluginUpdateLocale);
Vue.use(pluginCommonUI);

new Vue({
    i18n: new VueI18n({
        // locale: 'en',
        messages: initCommonToMessage(message),
    }),
    render: h => h(App),
    created() {
        this.$i18n.locale = localStorage.getItem(Key_i18n) || Value_Default_i18n;
        initAPMLabel(this);
    },

}).$mount('#app');

