export function initContentData(vm) {
    // 当语言切换时，更新页面的data
    vm.opennessData = {
        title: vm.$t('openness.title'),
        subTitle: vm.$t('openness.titleDesc'),
        more: vm.$t('openness.more'),
        data: [
            {
                title: vm.$t('openness.deviceTitle'),
                desc: vm.$t('openness.deviceDdesc'),
                className: '',
                icon: '&#xe65f;',
                list: [
                    [
                        vm.$t('openness.wifi'),
                        vm.$t('openness.bluetooth'),
                        vm.$t('openness.VPN'),
                        vm.$t('openness.NFC'),
                        vm.$t('openness.GPS'),
                    ],
                    [
                        vm.$t('openness.camera'),
                        vm.$t('openness.microphone'),
                        vm.$t('openness.SDCard'),
                        vm.$t('openness.fingerprintSensor'),
                        vm.$t('openness.USB'),
                    ],
                    [
                        vm.$t('openness.dataNetwork'),
                        vm.$t('openness.OTG'),
                        vm.$t('openness.screenshot'),
                        vm.$t('openness.accelerometer'),
                        vm.$t('openness.debug'),
                    ],
                ],
            },
            {
                title: vm.$t('openness.appTitle'),
                desc: vm.$t('openness.appDdesc'),
                className: 'w310',
                icon: '&#xe659;',
                list: [
                    [vm.$t('openness.app1'), vm.$t('openness.app2')],
                    [vm.$t('openness.app3'), vm.$t('openness.app4'), vm.$t('openness.app5')],
                    [vm.$t('openness.app6')],
                ],
            },
            {
                title: vm.$t('openness.dataTitle'),
                desc: vm.$t('openness.dataDdesc'),
                className: 'w250',
                icon: '&#xe65b;',
                list: [
                    [vm.$t('openness.data1'), vm.$t('openness.data2'), vm.$t('openness.data3')],
                    [vm.$t('openness.data4'), vm.$t('openness.data5'), vm.$t('openness.data6')],
                ],
            },
            {
                title: vm.$t('openness.callTitle'),
                desc: vm.$t('openness.callDesc'),
                className: 'w400',
                icon: '&#xe65a;',
                list: [
                    [vm.$t('openness.call1'), vm.$t('openness.call2')],
                    [vm.$t('openness.call3'), vm.$t('openness.call4')],
                ],
            },
            {
                title: vm.$t('openness.customTitle'),
                desc: vm.$t('openness.customDesc'),
                className: 'w203',
                icon: '&#xe658;',
                list: [
                    [vm.$t('openness.custom1'), vm.$t('openness.custom2')],
                    [vm.$t('openness.custom3'), vm.$t('openness.custom4'), vm.$t('openness.custom5')],
                ],
            },
        ],
    };

    vm.scenarios = {
        title: vm.$t('scenarios.title'),
        subTitle: vm.$t('scenarios.titleDesc'),
        data: [
            {
                iconClass: 'icon-shichangxiaoshou',
                title: vm.$t('scenarios.marketSalesTitle'),
                text: vm.$t('scenarios.marketSalesDesc'),
            },
            {
                iconClass: 'icon-zhihuiyiyuan',
                title: vm.$t('scenarios.smartHospitalTitle'),
                text: vm.$t('scenarios.smartHospitalDesc'),
            },
            {
                iconClass: 'icon-jiaoyuxinxihua',
                title: vm.$t('scenarios.educationalTitle'),
                text: vm.$t('scenarios.educationalDesc'),
            },
            {
                iconClass: 'icon-zhihuibangong',
                title: vm.$t('scenarios.smartOfficeTitle'),
                text: vm.$t('scenarios.smartOfficeDesc'),
            },
        ],
    };

    vm.advantage = {
        title: vm.$t('advantage.title'),
        subTitle: vm.$t('advantage.titleDesc'),

        data: [
            {
                icon: '&#xe64f;',
                title: vm.$t('advantage.title1'),
                text: vm.$t('advantage.text1'),
            },
            {
                icon: '&#xe650;',
                title: vm.$t('advantage.title2'),
                text: vm.$t('advantage.text2'),
            },
            {
                icon: '&#xe64d;',
                title: vm.$t('advantage.title3'),
                text: vm.$t('advantage.text3'),
            },
            {
                icon: '&#xe64c;',
                title: vm.$t('advantage.title4'),
                text: vm.$t('advantage.text4'),
            },
            {
                icon: '&#xe64b;',
                title: vm.$t('advantage.title5'),
                text: vm.$t('advantage.text5'),
            },
        ],
    };

    vm.process = {
        title: vm.$t('process.title'),
        subTitle: vm.$t('process.titleDesc'),
        data: [
            {
                text: vm.$t('process.step1'),
                icon: '&#xe645;',
            },
            {
                text: vm.$t('process.step2'),
                icon: '&#xe649;',
            },
            {
                text: vm.$t('process.step3'),
                icon: '&#xe647;',
            },
            {
                text: vm.$t('process.step4'),
                icon: '&#xe648;',
            },
            {
                text: vm.$t('process.step5'),
                icon: '&#xe64a;',
            },
        ],
    };

    vm.partners = {
        title: vm.$t('partners.title'),
        subTitle: vm.$t('partners.titleDesc'),
        data: [
            {
                id: 0,
                rank: 0,
                title: '',
                type: 2,
                url: 'https://f1.market.xiaomi.com/download/MiSafe/0e90047fee7e5ba7ba5965e468c3c92350d42828a/移动.png',
            },
            {
                id: 0,
                rank: 1,
                title: '',
                type: 2,
                url: 'https://f3.market.mi-img.com/download/MiSafe/05bf9956912d04eed1bbf471343f8a41078e6d746/电信.png',
            },
            {
                id: 0,
                rank: 2,
                title: '',
                type: 2,
                url:
                    'https://f3.market.mi-img.com/download/MiSafe/080bf5a4c8d10a9dad313eb682f13fd4cb140f1e7/奇安信.png',
            },
            {
                id: 0,
                rank: 3,
                title: '',
                type: 2,
                url: 'https://f5.market.xiaomi.com/download/MiSafe/0b88ab4eac6114d210b8e3b37e28e2c5d888094cb/启迪.png',
            },
            {
                id: 0,
                rank: 4,
                title: '',
                type: 2,
                url: 'https://f3.market.mi-img.com/download/MiSafe/080bf5a4c8d10a9dae313bb681f137d4c8140f1e7/嘉赛.png',
            },
            {
                id: 0,
                rank: 5,
                title: '',
                type: 2,
                url: 'https://f5.market.xiaomi.com/download/MiSafe/09900a4fed7e54a7b25968e46233c82b57ebec38e/TQ.png',
            },
            {
                id: 0,
                rank: 6,
                title: '',
                type: 2,
                url: 'https://f1.market.mi-img.com/download/MiSafe/060bf754c1d8049da0313db681713ed7ca1a3f4f8/遛牛.png',
            },
            {
                id: 0,
                rank: 7,
                title: '',
                type: 2,
                url: 'https://f2.market.mi-img.com/download/MiSafe/0e90047fee7e5ba7b95968e46cc3cc235fd42828a/云创.png',
            },
            {
                id: 0,
                rank: 8,
                title: '',
                type: 2,
                url: 'https://f1.market.mi-img.com/download/MiSafe/080bf5a4c8d10a9dae3135b682f13ad4ca140f1e7/虎鲸.png',
            },
            {
                id: 0,
                rank: 9,
                title: '',
                type: 2,
                url: 'https://f1.market.xiaomi.com/download/MiSafe/0304157fd367cd76a51c215aae04f1bdc524208ab/云客.png',
            },
            {
                id: 0,
                rank: 10,
                title: '',
                type: 2,
                url: 'https://f3.market.mi-img.com/download/MiSafe/02bf9956912d04eed1bbf571344f8a4e078e0d746/数知.png',
            },
            {
                id: 0,
                rank: 11,
                title: '',
                type: 2,
                url:
                    'https://f2.market.xiaomi.com/download/MiSafe/04bf9956912d04eed1bbf57134bf8a4c078edd746/维度科技.png',
            },
            {
                id: 0,
                rank: 12,
                title: '',
                type: 2,
                url: 'https://f3.market.mi-img.com/download/MiSafe/0a375f4d24efd4342151d9926014512507d8c5aa2/渔讯.png',
            },
            {
                id: 0,
                rank: 13,
                title: '',
                type: 2,
                url: 'https://f4.market.mi-img.com/download/MiSafe/073e594d1ed3247623bf4252af7dc2e23a9aa0146/尘锋.png',
            },
            {
                id: 0,
                rank: 14,
                title: '',
                type: 2,
                url:
                    'https://f5.market.xiaomi.com/download/MiSafe/026325e63aeb18ae6a95a623e1fe11dc1ae418636/yongfan.png',
            },
        ],
    };
}
