<template>
    <!-- 首页的内容面板 -->
    <div class="panel" :class="params.bgType === 1 ? 'bgcolor1' : ''">
        <h2 class="panel-title">
            <span>{{params.title}}</span>
            <small>{{params.subTitle}}</small>
        </h2>
        <div class="panel-content">
            <slot name="default"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        params: {
            type: Object,
            default() {
                return {
                    title: '',
                    subTile: '',
                };
            }
        }
    },
}
</script>

<style lang="less">
.panel {
    background: #fff;
    padding-top: 62px;

    &.bgcolor1 {
        background: #f6f6f7;
    }

    h2.panel-title {
        font-size: 30px;
        text-align: center;
        line-height: 42px;
        color: #5e5e5e;
        font-weight: normal;

        small {
            margin-top: 10px;
            display: block;
            color: #a1a1a1;
            font-size: 18px;
            line-height: 25px;

        }
    }
}

</style>